<script setup>
    import { ref, computed, onMounted } from 'vue';
    import { getOrCreateDataObject } from 'o365.vue.ts';
    import Sortable from 'sortable';
    
    const props = defineProps({
        dataObject: { type: Object, required: true },
        buttonAddPosition: { type: String, required: false, default: 'right' },
    });
    const sortItemsList = ref(null);

    const dsFields = getOrCreateDataObject({
        id: 'o_sort_dsFields',
        fields: [{ name: 'Name' }],
        data: []
    });

    onMounted(() => {
        dsFields.enableClientSideHandler([]);
        // dsFields.load();

        new Sortable(sortItemsList.value, {
            animation: 150,
            onEnd: (e) => updateSortOrder(e.oldDraggableIndex + 1, e.newDraggableIndex + 1)
        });
    });

    const updateDataObject = (selectedFieldName) => {
        dsFields.state.isLoading = true;
        let rows = [];
        dsFields.selectFirstRowOnLoad = !!selectedFieldName;
        if (selectedFieldName) {
            rows = [ { Name: selectedFieldName, current: true } ];
        }
        rows = rows.concat(lookupFieldsFiltered.value.map(o => ({ Name: o.name })));
        dsFields.enableClientSideHandler(rows);
        // dsFields.load();

        dsFields.state.isLoading = false;
    }

    const dataObjectFields = computed(() => {
        return props.dataObject.fields;
    });

    const lookupFieldsFiltered = computed(() => {
        return dataObjectFields.value.filter(f => [undefined, null].includes(f.sortOrder));
    });

    const fieldsWithSortOrder = computed(() => {
        return dataObjectFields.value.filter(f => f.sortOrder);
    });

    const fieldsWithSortOrderSorted = computed(() => {
        return fieldsWithSortOrder.value.sort((a, b) => a.sortOrder - b.sortOrder);
    });

    const addNewButtonStyle = computed(() => {
        return {
            left: props.buttonAddPosition === 'left' ? '15px' : undefined,
            right: props.buttonAddPosition === 'right' ? '15px' : undefined
        };
    });

    const sortOrderButtonIcon = (sortDirection) => {
        return {
            'bi-sort-up': sortDirection === 'asc',
            'bi-sort-down': sortDirection === 'desc'
        };
    }

    const addNewSortField = (selected) => {
        if (dataObjectFields.value[selected.Name].sortOrder) {
            return;
        }

        let maxSortOrder = dataObjectFields.value.fields.reduce((result, field) => {
            if (field.sortOrder > result) {
                return field.sortOrder;
            }

            return result;
        }, 0);

        dataObjectFields.value[selected.Name].sortOrder = maxSortOrder + 1;
        dataObjectFields.value[selected.Name].sortDirection = "asc";
        
        props.dataObject.load();
    }

    const changeSortField = (field, selected) => {
        dataObjectFields.value[selected.Name].sortOrder = field.sortOrder;
        dataObjectFields.value[selected.Name].sortDirection = field.sortDirection;

        dataObjectFields.value[field.name].sortOrder = null;
        dataObjectFields.value[field.name].sortDirection = null;
    }

    function updateSortOrder(oldSortOrder, newSortOrder) {
        if (oldSortOrder === newSortOrder) {
            return;
        }

        let affectedObjects = dataObjectFields.value.filter(obj => {
            return obj.sortOrder >= Math.min(oldSortOrder, newSortOrder) && obj.sortOrder <= Math.max(oldSortOrder, newSortOrder);
        });
    
        affectedObjects.forEach(obj => {
            if (obj.sortOrder === oldSortOrder) {
                obj.sortOrder = newSortOrder;
            } else {
                obj.sortOrder += (oldSortOrder < newSortOrder ? -1 : 1);
            }
        });

        props.dataObject.load();
    }

    const reverseSortDirection = (fieldName) => {
        let oldSortDirection = dataObjectFields.value[fieldName].sortDirection;

        let newSortDirection = oldSortDirection == "asc" ? "desc" : "asc";

        dataObjectFields.value[fieldName].sortDirection = newSortDirection;
        props.dataObject.load();
    }

    const removeSortOrder = (fieldName) => {
        let oldSortOrder = dataObjectFields.value[fieldName].sortOrder;

        dataObjectFields.value[fieldName].sortDirection = null;
        dataObjectFields.value[fieldName].sortOrder = null;

        for (let field of dataObjectFields.value.fields) {
            if (field.sortOrder > oldSortOrder) {
                field.sortOrder--;
            }
        }

        props.dataObject.load();
    }
</script>

<template>
    <ul ref="sortItemsList" class="list-group">
        <template v-if="fieldsWithSortOrderSorted.length > 0">
            <li class="d-flex list-group-item sort-list-item pb-3" v-for="field in fieldsWithSortOrderSorted" :key="field.name">
                <span class="bi bi-grip-vertical align-self-center" style="font-size:30px;"></span>

                <span class="me-3" style="font-size:22px;">{{ field.sortOrder }}.</span>
                
                <div class="d-flex flex-column flex-1" style="row-gap:10px;">
                    <ODataLookup 
                        @beforeopen="updateDataObject(field.name)"
                        :data-object="dsFields"
                        v-model="field.name"
                        :bind="(selected) => changeSortField(field, selected)"
                        class="form-control w-100"
                        disablePinned
                        disableRecent>
                            <OColumn field="Name"></OColumn>
                    </ODataLookup>
                    
                    <div class="d-flex justify-content-between">
                        <div class="btn-group">
                            <button type="button" class="btn btn-outline-secondary text-nowrap" @click="reverseSortDirection(field.name)">
                                <span>{{ field.sortDirection === 'asc' ? 'Ascending' : 'Descending' }}</span>
                                <span class="bi ms-2" :class="sortOrderButtonIcon(field.sortDirection)"></span>
                            </button>
                        </div>

                        <button type="button" class="btn btn-outline-danger" @click="removeSortOrder(field.name)">
                            <i class="bi bi-trash"></i>
                        </button>
                    </div>
                </div>
            </li>
        </template>
        <template v-else>
            <span>There are no sort items</span>
        </template>
    </ul>
    
    <ODataLookup 
        :data-object="dsFields" 
        :bind="addNewSortField" 
        @beforeopen="updateDataObject"
        disablePinned
        disableRecent>
        <OColumn field="Name"></OColumn>
        <template #target="{target}">
            <button :ref="target" type="button" class="btn btn-primary button-add-field" :style="addNewButtonStyle">
                <span>Add Field</span>
                <i class="bi bi-plus-circle ms-2"></i>
            </button>
        </template>
    </ODataLookup>
</template>

<style scoped>
    .sort-list-item {
        gap: 10px;
        align-items: center;
        border-radius: 0;
        border-width: 0 0 1px 0;
    }
    .button-add-field {
        position: absolute;
        bottom: 15px;
    }
</style>